import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmltotext'
})
export class HtmltotextPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value.replace(/<[^>]+>/gm, '') : '';
  }

}
