import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {
  @Input() name!: string;
  @Input() inputType = 'text';
  @Input() placeholder!: string;
  @Input() value!: string;
  @Input() error?: string;
  @Input() isRequired = false;
  @Input() style?: { [key: string]: string };
  @Output() valueChange = new EventEmitter<string>();
  isFocused = false;
  isPasswordVisible = false;
  handleFocus() {
    this.isFocused = true;
  }

  handleBlur() {
    this.isFocused = false;
  }

  handleInputChange(event: any) {
    this.valueChange.emit(event.target.value);
  }

  togglePasswordVisibility(event: MouseEvent) {
    event.preventDefault();
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  constructor() { }

  ngOnInit() {
  }

}
