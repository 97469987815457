import { CacheKeys } from './../shared/utils/cache-keys';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { StorageManager } from '../shared/utils/storage-manager';
import { Utils } from '../shared/utils/utils';


@Injectable()
export class AuthenticationGaurd implements CanActivate {
    constructor(public router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        let token = StorageManager.getManager().getByType<any>(CacheKeys.JWT_TOKEN);
        if (token && !Utils.isEmpty(token) && !Utils.isEmptyString(token['access_key']) && token['identity'] == 'user')
            return true;
        this.router.navigate(["/login"]);
    }
}