

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { AppConstants } from 'src/app/common-scripts/AppConstants';
import { SendInvitations } from 'src/app/shared/objects/SendInvitations';
import { Observable } from 'rxjs';


@Injectable()
export class UserInvitationControllerService {

    protected basePath ;
    public defaultHeaders = new HttpHeaders();

    constructor(protected httpClient: HttpClient) {
    
            this.basePath = AppConstants.baseURL ;
    }


    public getAllInvitationsUsingGET(): Observable<Array<SendInvitations>> {

        

        return this.httpClient.get<Array<SendInvitations>>(`${this.basePath}invitations`,
            {
              
            }
        );
    }

    public submitInvitationUsingPOST(invitationDTO: SendInvitations): Observable<any>{

        if (invitationDTO === null || invitationDTO === undefined) {
            throw new Error('Required parameter invitationDTO was null or undefined when calling submitInvitationUsingPOST.');
        }

    

        return this.httpClient.post<any>(`${this.basePath}invitation`,
            invitationDTO,
            {
            }
        );
    }



}
