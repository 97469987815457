import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  SendInvitations
} from 'src/app/shared/objects/SendInvitations';
import {
  CommonServiceService
} from 'src/app/shared/services/common-service.service';
import {
  NgxSmartModalService
} from 'ngx-smart-modal';
import {
  UserInvitationControllerService
} from 'src/app/users/services/userInvitationController.service';
import {
  NgForm,
  Validators
} from '@angular/forms';
import {
  AlertUtils
} from '../../utils/alert-utils';
import {
  SystemPropertiesControllerService
} from 'src/app/miscellaneous/services/systemPropertiesController.service';
import {
  SystemPropertiesKey
} from '../../models/systemPropertiesKey';
import { AppConfigService } from 'src/app/common-scripts/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { AdminList } from 'src/app/globalIndicator/interfaces/global-indicator';
import { UserManagmentService } from 'src/app/users/services/usermanagmentservice.service';
import { Admin } from 'src/models/dto/ArticleResponseDTO';
import { User, PendingUsersResponse } from '../../interface/user';
import { CacheKeys } from '../../utils/cache-keys';
import { applyPasswordPolicy, matchPassword } from '../../utils/forms-validators';

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.css']
})
export class SendInvitationComponent implements OnInit {
  @Input() show: boolean;
  @Output() close = new EventEmitter<void>(); 
   
  invitation: SendInvitations;
  constructor(public ngxSmartModalService: NgxSmartModalService, 
    private commonService: CommonServiceService,
    private userInvitationControllerService: UserInvitationControllerService, 
    private systemPropertiesControllerService: SystemPropertiesControllerService,
    private appConfig: AppConfigService,private userService:UserManagmentService, private toastr: ToastrService) {}
    inviteModalOpeninvite:boolean
    public name: string;
    pendingapproved:User[]=[];
    adminList:Admin[]=[];
    itemsNUmber:number
    email:string;
    loading:boolean;
    uploadingFIle:boolean;
    type:string;
    openinv:boolean;
  

  
    ngOnInit() {
      
      this.loadapprovedUsers()
      this.loadAllAdmins()
    }
  
 
    triggerEvent() {
      // Emit an event to notify the parent
      this.close.emit();
    }
  
  
    inviteButtonClick() {
      console.log('start invite user');
      this.openinv=true
  
      this.commonService.inviteButtonClick.emit();
    }
    openInvite(){
       this.openinv=true
    }
    closePopup(){
      this.show=false;
    }
    loadapprovedUsers(): void {
   
      this.userService.getAllpendingUsers().subscribe(
          (response: PendingUsersResponse) => {
              console.log('API Response:', response);
              this.pendingapproved = response.data || []; // Default to empty array if undefined
              this.itemsNUmber=response.totalItems;
              this.loading = false;
              // this.totalItems = response.totalItems;
              // this.totalPages = Math.ceil(this.totalItems / this.size);
          },
          (error) => {
              console.error('Error loading pending users:', error);
          }
      );
      // this.showpending=false;
      // this.showapproved=true
    
    }
    loadAllAdmins(): void {
   
      this.userService.getAllAdmins().subscribe(
          (response: AdminList) => {
              console.log('API Response:', response);
              this.adminList= response.data || []; // Default to empty array if undefined
              this.itemsNUmber=response.totalItems;
              this.loading = false;
              // this.totalItems = response.totalItems;
              // this.totalPages = Math.ceil(this.totalItems / this.size);
          },
          (error) => {
              console.error('Error loading pending users:', error);
          }
      );
      // this.showpending=false;
      // this.showapproved=true
    
    }
  
    inviteUserstoGPI(){
      this.uploadingFIle=true
      const formdata=new FormData();
  
      formdata.append('email',this.email)
  
  
      this.userService.inviteUsers(formdata).subscribe((resp)=>{
  
  this.toastr.success('The Invitation Was Sent Suceessfully ')
  this.uploadingFIle=false
      },(err)=>{
        if(err.status==409)
           {
            this.toastr.warning(err.error.message)
           }
           else{
            this.toastr.error('The invitation wasn \'n sent ')
           }
       
        this.uploadingFIle=false
      }
    
    
    
    
    )
    }
  
  
    inviteAdmintoGPI(){
      this.uploadingFIle=true
      const formdata=new FormData();
  
      formdata.append('email',this.email)
  
  
      this.userService.inviteAdmin(formdata).subscribe((resp)=>{
  
  this.toastr.success('The Invitation Was Sent Suceessfully ')
  this.uploadingFIle=false
      },(err)=>{
        if(err.status==409)
           {
            this.toastr.warning(err.error.message)
           }
           else{
            this.toastr.error('The invitation wasn \'n sent ')
           }
       
        this.uploadingFIle=false
      }
    
    )}
    invite(){
      if (this.type=='User'){
        this.inviteUserstoGPI()
      }else if(this.type=='Employee'){
        this.inviteAdmintoGPI()
      }
    }
  
  

}
