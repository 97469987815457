import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {
  @Input() show: boolean;


  constructor() { }

  ngOnInit() {
  }
  refresh(){
    window.location.reload()
    }
}
