export class AppConstants {

  public static ANONYUN = 'anonymous';
  public static ANONYPS = 'anonymous$$QoF$$SSPP!!';

  public static get baseURL(): string {
    return '/';

  }
}
