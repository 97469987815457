import { HttpHeaders } from '@angular/common/http';
import { ContentType } from 'src/app/indicator/services/interface/content-type';


declare let jQuery: any;


export class Utils {

  public static ALLOWED_ATTACHMENT_TYPES = ['jpg', 'jpeg', 'png', 'pdf', 'mp4'];
  public static XSS_PATTERNS = [
    "<script>(.*?)</script>",
    "src[\r\n]*=[\r\n]*\\\'(.*?)\\\'",
    "</script>",
    "<script(.*?)>",
    "eval\\((.*?)\\)",
    "expression\\((.*?)\\)",
    "javascript:",
    "vbscript:",
    "onload(.*?)="
   ]


  public static isEmptyString(obj): boolean {
    return Utils.isEmpty(obj) || obj == '';
  }

  public static isEmpty(obj): boolean {
    return obj == null || obj == undefined;
  }

  public static clone(obj: any): any {
    return jQuery.extend(true, {}, obj);
  }

  public static getYearsList(): any {

    const offsetYear = 2015;
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 0; i <= currentYear - offsetYear; i++)
      years.push(offsetYear + i);

    return years;
  }

  public static getYearsListUntilLastYear(): any {

    const offsetYear = 2015;
    const years = [];
    const currentYear = new Date().getFullYear() - 1;

    for (let i = 0; i <= currentYear - offsetYear; i++)
      years.push(offsetYear + i);

    return years;
  }


  public static isFileTypeAllowed(file: File): boolean {

    for (const type of this.ALLOWED_ATTACHMENT_TYPES) {
      if (file.type.indexOf(type) != -1)
        return true;
    }
    return false;
  }

  public static isFileSizeAllowed(file: File): boolean {
    return file.size / 1024 / 1024 < 2;
  }


  public static base64UrlString(base64String: string, filename?: string) {
    // if (!filename) {
    //   return;
    // }

    let mimeType;

    if (filename) {
      mimeType = Utils.mimeType(filename);
    } else {
      const base64DecodedString = atob(base64String);

      const base64DecodedStringLC = base64DecodedString.toLowerCase();

      
      if(base64DecodedStringLC.indexOf("data:") >= 0 && base64DecodedStringLC.indexOf("base64") >= 0){
        return base64DecodedString;
      }

      mimeType = this.ALLOWED_ATTACHMENT_TYPES.find(type => (base64DecodedStringLC.indexOf(type) >= 0));

    }
    const type = Utils.contentType(mimeType);

    return `data:${type}/${mimeType};base64,${base64String}`;
  }

  public static mimeType(filename: string): string {
    return filename.split('.').pop();
  }

  public static contentType(mimeType: string): ContentType {
    if (mimeType == 'pdf') {
      return ContentType.Application
    } else if (mimeType == 'mp4') {
      return ContentType.Video
    } else {
      return ContentType.Image
    }
  }

  
  public static findXSS(str:string):boolean{
    const result = this.XSS_PATTERNS.find(xssPattern=>{
      const regex = new RegExp(xssPattern,'gmis');
      
      return regex.test(str);

    })

    return !!result;
}

public static sanitize(dataURL:string):string{
  if(!Utils.findXSS(dataURL)){
    return dataURL;
  }else{
    return '';
  }
}




}