
import { Injectable } from '@angular/core';

@Injectable({providedIn:"root"})
export class LoadingService {

  private requestNumber: number = 0;
  
  constructor() { }

  public AddNewRequest() {
    ++this.requestNumber;
  }

  public RequestFinished() {
    --this.requestNumber;
    if (this.requestNumber < 0)
      this.requestNumber = 0;
  }

  public isVisible()  : boolean{
    return this.requestNumber >0;
  }


}
