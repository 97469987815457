import { LoadingInterceptor } from './../interceptor/loading-interceptor';
import { ErrorComponent } from './../error/error.component';
/* import { JWtInterceptor } from './../interceptor/jwt-interceptor'; */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NumbersOnlyDirective } from './directives/NumbersOnly.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SendInvitationComponent } from './components/send-invitation/send-invitation.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MapsModule } from './components/maps/maps.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentViewDialogComponent } from './components/content-view-dialog/content-view-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormInputComponent } from '../form-input/form-input.component';
import { LoadingComponent } from '../loading/loading.component';
import { LoadingDataComponent } from '../loading-data/loading-data.component';
import { FormBuilderInputComponent } from '../form-builder-input/form-builder-input.component';
import { QuillModule } from 'ngx-quill';
import { PageErrorComponent } from '../page-error/page-error.component';
import { HtmltotextPipe } from '../htmltotext.pipe';


/* export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JWtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },

]; */

@NgModule({
  declarations: [
    NumbersOnlyDirective,
    ErrorComponent,
    SendInvitationComponent,
    ContentViewDialogComponent,
    FormInputComponent,
    LoadingComponent,
    SendInvitationComponent,
    LoadingDataComponent,
    FormBuilderInputComponent,   
     PageErrorComponent,
     HtmltotextPipe

  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MapsModule,
    NgxSmartModalModule.forRoot(),
    FlexLayoutModule.withConfig({
      addFlexToParent: false
    }),
    MatDialogModule,
    QuillModule
    
  ],

  exports:[
    MapsModule,
    ErrorComponent,
    SendInvitationComponent,
    FlexLayoutModule,
    ContentViewDialogComponent,
    FormInputComponent,
    LoadingComponent,
    SendInvitationComponent,
    LoadingDataComponent,
    QuillModule,
    FormBuilderInputComponent,
    PageErrorComponent,
    HtmltotextPipe
  ],
  entryComponents:[ContentViewDialogComponent]
})
export class SharedModule { }
