export class Utilities{
    
    public static async getGeoData(countryName: string){
        const FILTERED_COUNTRIES = ['United States', 'United Arab Emirates', 'United Kingdom']
        const getCountryName = () => {
          const camelize = (str) => {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
              return index == 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');
          }
    
          let result: string = countryName;
    
          if (FILTERED_COUNTRIES.includes(countryName)) {
    
            if (countryName == 'United States') {
              result = 'usa';
            } else if (countryName == 'United Arab Emirates') {
              result = 'uae';
            } else if (countryName == 'United Kingdom') {
              result = 'uk';
            }
    
          } else {
            result = camelize(countryName);
          }
    
          return result;
        }
    
        return await import(`@amcharts/amcharts4-geodata/${getCountryName()}High`);
      }

}