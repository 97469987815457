import { CacheKeys } from 'src/app/shared/utils/cache-keys';

export class StorageManager {
    private static manager: StorageManager;

    private constructor() {
    }

    public static getManager(): StorageManager {
        if (this.manager == null) {
            this.manager = new StorageManager();
        }
        return this.manager;
    }

    public set(key: string, value): void {
        if (typeof value === 'string') {
            if (this.isSessionStorage())
                sessionStorage.setItem(key, value);
            else
                localStorage.setItem(key, value);
        } else {
            if (this.isSessionStorage())
                sessionStorage.setItem(key, JSON.stringify(value));
            else
                localStorage.setItem(key, JSON.stringify(value));

        }
    }

    public get(key: string): string {
        if (this.isSessionStorage())
            return sessionStorage.getItem(key);
        else
            return localStorage.getItem(key);

    }

    public getByType<T>(key: string): T {
        if (this.isSessionStorage())
            return JSON.parse(sessionStorage.getItem(key)) as T;
        else
            return JSON.parse(localStorage.getItem(key)) as T;

    }

    public contains(key: string): boolean {
        if (this.isSessionStorage())
            return sessionStorage.getItem(key) != null;
        else
            return localStorage.getItem(key) != null;
    }

    public clear() {
        if (this.isSessionStorage())
            sessionStorage.clear();
        else{
            localStorage.clear();
            //when clearing local session just set global session expiration flag
            // localStorage.removeItem(CacheKeys.REMEBER_ME);
            // localStorage.setItem('global_session_expired' , 'true');
        }
    }

    
    public clearBoth() {   
        sessionStorage.clear();      
        localStorage.clear();
    }

    public initSessionMode(rememberMe) {   
        localStorage.setItem(CacheKeys.REMEBER_ME,rememberMe);
    }

    public remove(key: string) {
        if (this.isSessionStorage())
            sessionStorage.removeItem(key);
        else
            localStorage.removeItem(key);
    }

    public isSessionStorage(): boolean {
        let isSession = localStorage.getItem(CacheKeys.REMEBER_ME) !== 'true';
        return  isSession;
    }


}