import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceUtils } from 'src/app/shared/utils/service-utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private apiUrl = environment.apiUrl


  constructor(private http: HttpClient, private router: Router) {

  }

  public register(fullname: string, username: string, email: string, password: string,token:string) : Observable<any> {
    let url = this.apiUrl + ServiceUtils.REG_URL
    return this.http.post(url, { fullName: fullname, username: username, email: email, password: password ,token:token}).pipe(
      catchError(ServiceUtils.handleError)
    )

  }


}
