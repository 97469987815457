import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-builder-input',
  templateUrl: './form-builder-input.component.html',
  styleUrls: ['./form-builder-input.component.scss'],
})
export class FormBuilderInputComponent implements OnInit {
  @Input() style?:any;
  @Input() formControlName:any;
  @Input() type?:string;
  @Input() label:string;
  @Input() formGroup:FormGroup;


  constructor() { }

  ngOnInit() {
  }

}
