import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements OnInit {
  @Input() isChecked = false;
  @Input() content!: any; // Can adjust this type to match the expected content type
  @Output() clicked = new EventEmitter<void>();
  iconPath = '../../assets/ic_checkbox_unchecked.webp'; // Default unchecked icon

  // Detect changes in the `isChecked` property and update icon accordingly
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isChecked']) {
      this.iconPath = this.isChecked ? '../../assets/ic_checkbox_checked.webp' : '../../assets/ic_checkbox_unchecked.webp';
    }
  }

  // Emit the click event to the parent
  onClick(): void {
    this.clicked.emit();
  }

  constructor() { }

  ngOnInit() {
  }

}
