import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../new-login/service/login-service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  loading:boolean=false
  formError:string=""
  workEmail:string=""
  successMessage:string=""

  constructor(private router:Router,private loginService:LoginService) { }

  ngOnInit() {
  }
  isEmailValid(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  onWorkEmailChange(newValue : string):void {
    this.formError = ""
    if (!this.isEmailValid(newValue)) {
      this.formError = "Please enter a valid email address"
      return;
    }
    this.workEmail = newValue
  }
  navigateToLogin():void {
    this.router.navigate(["login"])
  }
  resetPassword(email:string) {
    this.loginService.forgetPassword(email).subscribe(() => {
      this.loading=false
      this.successMessage="Link sent successfully, Please check your email to proceed !"
    },error => {
      this.loading=false
      this.formError=error
    })
  }
  submitForm():void {
    this.loading=true
    if (this.formError!=="") {
      this.loading=false
      return
    }
    this.resetPassword(this.workEmail)


  }

}
