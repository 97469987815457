import { Component, OnInit } from '@angular/core';
import { LoginService } from '../new-login/service/login-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  password: string = ""
  confirmPassword: string = ""
  passwordError: string = ""
  confirmPasswordError: string = ""
  formError: string = ""
  successMessage: string = ""
  loading: boolean = false
  token: string = ""


  constructor(private loginService: LoginService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get("token");
    })


  }
  changePassword(token: string, password: string) {
    this.loading = true
    this.loginService.resetPassword(token, password).subscribe(() => {
      this.loading = false
      this.successMessage = "Password reset successfully ! redirecting to login screen in 3 seconds ..."
      setTimeout(() => {
        this.router.navigate(["/login"])
      }, 3000);

    }, error => {
      this.loading = false
      this.formError = error
    })
  }
  isPasswordValid(password: string): boolean {
    return (password.length > 3 && password.length < 50)
  }
  onPasswordChange(newValue: string): void {
    this.passwordError = ""
    if (!this.isPasswordValid(newValue)) {
      this.passwordError = "Password must be at least 3 characters long and at most 50 characters long"
    }
    this.password = newValue
  }
  onConfirmPasswordChange(newValue: string): void {
    this.confirmPasswordError = ""
    if (newValue != this.password) {
      this.confirmPasswordError = "Passwords do not match"
    }
    this.confirmPassword = newValue
  }
  submitForm(): void {
    if (this.passwordError || this.confirmPasswordError) {
      return
    }
    this.changePassword(this.token, this.password)


  }

}
