
import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/common-scripts/AppConstants';
import { IndicatorsCategories } from 'src/app/shared/objects/indicatorsCategories';
import { Observable, throwError } from 'rxjs';
import { ServiceUtils } from 'src/app/shared/utils/service-utils';
import { SaveIndicatorCategoriesRequest } from './interface/indicator-categories';
import { catchError } from 'rxjs/operators';
import { CountrySecurityIndex, Country, CountryDomain, Countrie } from './interface/countries';
import { StorageManager } from 'src/app/shared/utils/storage-manager';
import { CacheKeys } from 'src/app/shared/utils/cache-keys';
import { CitiesDomain } from './interface/cities';
import { error } from 'console';
import { data } from 'jquery';

@Injectable()
export class indicatorScurityIndexService {
  private domainData: any;
  private apiUrl = environment.apiUrl
  protected basePath;
  constructor(protected httpClient: HttpClient) {
    this.basePath = AppConstants.baseURL;
  }

  addPreviewImage(image: FormData, id: number) {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken);
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error('Error parsing token:', error);
      return throwError('Invalid token format');
    }

    if (!accessKey) {
      console.error('No access_key found in token!');
      return throwError('No access_key found');
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessKey}`);
    const url = `${this.apiUrl}/countries/${id}/photo`
    return this.httpClient.post(url, image, { headers })

  }

  getCountryById(id: number) {

    const url = `${this.apiUrl}/countries/${id}`
    return this.httpClient.get<Countrie>(url).pipe(catchError((error: any) => {
      console.error(error);
      return throwError(error);
    }))
  }

  getCountrieSecuritIndexData(year: number) {
    let url
    if (year > 0) {
      url = `${this.apiUrl}/securityindex/countries?year=${year}`
    } else {

      url = `${this.apiUrl}/securityindex/countries`
    }
    console.log('url', url);

    return this.httpClient.get<CountrySecurityIndex>(url).pipe(catchError((error: any) => {
      console.error(error);
      return throwError(error);
    }))
  }


  setDomainData(data: any) {
    this.domainData = data;
  }

  getDomainData() {
    return this.domainData;
  }

  saveUploadFile(file: File, year: string) {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error('Error parsing token:', error);
      return throwError('Invalid token format');
    }

    if (!accessKey) {
      console.error('No access_key found in token!');
      return throwError('No access_key found');
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessKey}`);
    const url = `${this.apiUrl}/securityindex/countries`;

    const formData = new FormData();
    formData.append('file', file); // Append the file
    formData.append('year', year); // Append the selected year

    return this.httpClient.post(url, formData, { headers,responseType: 'text'  }).pipe(
      catchError(error => {
        console.error('Upload failed:', error);
        return throwError('File upload failed');
      })
    );
  }
  getStatusCountriesData(taskId: string) {
    const url = `${this.apiUrl}/securityindex/countries/upload-state/${taskId}`
    return this.httpClient.get<string>(url).pipe(catchError((err: any) => {
      console.error(err);
      return throwError(err)
    }))
  }
  getAllCountries(): Observable<Country[]> {
    const url = `${this.apiUrl}/countries/`
    return this.httpClient.get<Country[]>(url).pipe(catchError((error: any) => {
      console.error(error);
      return throwError(error);
    }))
  }

  getAllDomainCountries() {
    const url = `${this.apiUrl}/securityindex/countries-domains`
    return this.httpClient.get<CountryDomain[]>(url).pipe(catchError((err: any) => {
      console.error(err);
      return throwError(err)
    }))
  }
}