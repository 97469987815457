import {
  Component,
  OnInit,
  NgZone,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldUltra from "@amcharts/amcharts4-geodata/worldHigh";
import am4geodata_continentsHigh from "@amcharts/amcharts4-geodata/continentsHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import {
  __assign
} from 'tslib';
import {
  TestBed
} from '@angular/core/testing';
import {
  AbstractMapComponent
} from '../abstract-map-component';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-flat-map',
  templateUrl: './flat-map.component.html',
  styleUrls: ['./flat-map.component.scss']
})
export class FlatMapComponent extends AbstractMapComponent implements OnInit, AfterViewInit, OnChanges {
  
  @Input() colorMode: string;
  @Output() onSeeFullData = new EventEmitter();
  @Output() onCountryHover = new EventEmitter();
  @Output() selectedCountryChange = new EventEmitter();
  @Input() countrySelect: boolean;
  @Input() zoomControls: boolean;
  private _worldSeries: am4maps.MapPolygonSeries;
  private _selectedCountry: string;

  @Input()
  set selectedCountry(countryCode: string) {
    this.removeSelectedState(this._selectedCountry);
    this._selectedCountry = countryCode;
    this.setSelectedState(this._selectedCountry);
    this.selectedCountryChange.emit(countryCode);
  }

  get selectedCountry() {
    return this._selectedCountry;
  }

  constructor(private zone: NgZone) {
    super();
    addEventListener('SeeFullDataClickEvent', this.handleSeeFullDataClick);

  }

  render(indicatorData ? : any[], tooltip ? : boolean) {

    if(this._map){
        this._map.dispose();
    }

    this._map = am4core.create(this.id, am4maps.MapChart);
    this._map.geodata = am4geodata_worldUltra;
    this._map.projection = new am4maps.projections.Miller();
    this._map.seriesContainer.draggable = true;
    this._map.seriesContainer.events.disableType("doublehit");
    this._map.background.events.disableType("doublehit");


    if (this.colorMode == 'continent') {
      //continents
      let continentsSeries = this._map.series.push(new am4maps.MapPolygonSeries());
      continentsSeries.geodata = am4geodata_continentsHigh;
      continentsSeries.useGeodata = true;
      continentsSeries.exclude = ["antarctica"];
      continentsSeries.background.fillOpacity = 0;
      // continentsSeries.mapPolygons.template.fill = am4core.color("red");
      continentsSeries.mapPolygons.template.fillOpacity = 1;

      let continentTemplate = continentsSeries.mapPolygons.template;
      continentTemplate.propertyFields.fill = "color"
      continentTemplate.propertyFields.stroke = "color"

      // continentsSeries.

      continentsSeries.data = [{
        "id": "africa",
        "color": am4core.color("#ffda83")
      }, {
        "id": "asia",
        "color": am4core.color("#5ee2a0"),
        // "zoomLevel": 2,
        // "zoomGeoPoint": {
        //   "latitude": 46,
        //   "longitude": 89
        // }
      }, {
        "id": "oceania",
        "color": am4core.color("#55D8FE")
      }, {
        "id": "europe",
        "color": am4core.color("#3B86FF")
      }, {
        "id": "northAmerica",
        "color": am4core.color("#A3A0FB")
      }, {
        "id": "southAmerica",
        "color": am4core.color("#ff8373")
      }];
      // Series for World map
    }
    this._worldSeries = this._map.series.push(new am4maps.MapPolygonSeries());
    this._worldSeries.useGeodata = true;
    this._worldSeries.calculateVisualCenter = true;

    __assign(this._worldSeries.data, indicatorData);
    this._worldSeries.exclude = ["AQ"];


    let worldTemplate = this._worldSeries.mapPolygons.template;

    if (this.colorMode == 'continent') {
      worldTemplate.fill = am4core.color("transparent");
      worldTemplate.fillOpacity = 0;
      worldTemplate.stroke = am4core.color("#c7c7c7");
      worldTemplate.strokeOpacity = 1;
    } else {
      this._map.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#9cc2f3");
      this._map.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;
      worldTemplate.fill = am4core.color("#f9f9f9");
      worldTemplate.stroke = am4core.color("#c7c7c7");
    }

    worldTemplate.nonScalingStroke = true;
    worldTemplate.propertyFields.id = "id";

    if (tooltip) {

      worldTemplate.tooltipHTML =
        `<div class="flatmap-tooltip" style="display:flex;flex-direction:column; justify-content: center;">
                    <div class="text" style="text-align: center;">{name} &nbsp;{value}</div>
                <div>`;

      this._worldSeries.tooltip.label.interactionsEnabled = true;
      this._worldSeries.tooltip.keepTargetHover = true;
      this._worldSeries.tooltip.getFillFromObject = false;
      this._worldSeries.tooltip.background.fill = am4core.color("#FFFFFF");
      this._worldSeries.tooltip.autoTextColor = false;
      this._worldSeries.tooltip.label.fill = am4core.color("#000000");
      // this._worldSeries.tooltip.label.align = "center";
      this._worldSeries.tooltip.label.fontSize = "1.5rem";
      worldTemplate.tooltipPosition = "fixed";
    }

    this._worldSeries.events.on("inited", () => {
      this._worldSeries.mapPolygons.each(country => {
        let countryData = country.dataItem.dataContext;

        country.events.on('over', event => {
          this.onCountryHover.emit({
            code: countryData["id"],
            name: countryData["name"]
          })

          if (countryData["id"] != this.selectedCountry) {
            country.fill = am4core.color("#ffffff");
            country.fillOpacity = 0.40;
          }
        });

        country.events.on('out', event => {
          if (countryData["id"] != this.selectedCountry) {
            country.fill = am4core.color("transparent");
            country.fillOpacity = 0;
          }
        });

        country.events.on('hit', event => {
          if(this.countrySelect)
          this.selectedCountry = countryData["id"];
        })
      })
    });

    this._map.events.on('ready', event => {
      this.setSelectedState(this._selectedCountry);
    });

    this._map.backgroundSeries.mapPolygons.each(background => {
      background.events.on('over', event => {
        this.onCountryHover.emit(null);
      })
    });

    if (indicatorData && indicatorData.length > 0) {
      let indicatorDataSeries = this._map.series.push(new am4maps.MapPolygonSeries())
      let indicatorDataTemplate = indicatorDataSeries.mapPolygons.template;
      indicatorDataTemplate.fill = am4core.color("#008755");
      indicatorDataTemplate.strokeOpacity = 1;
      indicatorDataTemplate.stroke = am4core.color("#FFFFFF");
      indicatorDataTemplate.strokeWidth = 2;
      indicatorDataTemplate.fillOpacity = 1;



       this._worldSeries.events.on("inited", () => {
         let countriesInMap = this._worldSeries.mapPolygons;
       countriesInMap.each(country => {
         let count = country.dataItem.dataContext["value"];

         if (count > 0) {
           if (country.dataItem.dataContext["id"] == indicatorData[0].id) {
             country.isHover = true;
           }

           let marker = indicatorDataSeries.mapPolygons.create();
           marker.multiPolygon = am4maps.getCircle(country.visualLongitude, country.visualLatitude, Math.max(0.2, Math.log(count*10000) * Math.LN10 / 10));


           // marker.tooltipText = country.dataItem.dataContext.name + ": " + count;
           country.dummyData = marker;
           marker.events.on("over", function () {
             country.isHover = true;
           })
           marker.events.on("out", function () {
             country.isHover = false;
           })
         }
         else {
           // country.tooltipText = country.dataItem.dataContext.name + ": no data";
           country.fillOpacity = 0;

           country.events.disableType("over");
         }


       })

       });

    }



  }

  removeSelectedState = (countryCode: string) => {
    if (this._worldSeries && this._worldSeries.getPolygonById(countryCode)) {
      this._worldSeries.getPolygonById(countryCode).fill = am4core.color("transparent");
      this._worldSeries.getPolygonById(countryCode).fillOpacity = 0;
    }
  }

  setSelectedState = (countryCode: string) => {
    if (this._worldSeries && this._worldSeries.getPolygonById(countryCode)) {
      // const zoomLevel = 5;
      this._worldSeries.getPolygonById(countryCode).fill = am4core.color("#000000");
      this._worldSeries.getPolygonById(countryCode).fillOpacity = 0.65;
      this._map.zoomToMapObject(this._worldSeries.getPolygonById(countryCode));
    }
  }

  viewWorld = () => {
    this._map.goHome();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.ngAfterViewInit();
  }

  ngOnInit() {
    if (!this.id) {
      this.id = 'mapDiv_' + (new Date()).getMilliseconds();
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      try{
        this.render(this.indicatorData, this.tooltip);
      }
      catch{}
    });

  }

  handleSeeFullDataClick = (event) => {
    this.onSeeFullData.emit({
      id: event.detail.id
    });
  }

}