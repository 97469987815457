import { Component } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import { LoginService } from './new-login/service/login-service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'web';

  constructor(private loginService : LoginService){
    console.log(isDevMode());
  }

  ngOnInit() {

    am4core.options.queue = true;
    am4core.options.onlyShowOnViewport = true;


    // TODO REMOVED -- this.loginService.acquireAnonymousToken();
  }
}
