import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError, EMPTY } from "rxjs";
import { StorageManager } from '../shared/utils/storage-manager';
import { CacheKeys } from '../shared/utils/cache-keys';
import { LoginService } from '../new-login/service/login-service';

@Injectable()
export class JWtInterceptor implements HttpInterceptor {

    constructor(public router: Router, private loginService: LoginService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = req;

        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
               
                if (error.status === 401 && (error.error.internalCode === 1001 || error.error.internalCode === 1002)) {
                    return this.handleTokenExpired(req, next);
                }
                console.log("Error", error);
                return throwError(error)
            })
        );
    }
    private handleTokenExpired(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const refreshToken = StorageManager.getManager().getByType<string>(CacheKeys.JWT_REFRESH_TOKEN);
       
        // if (!refreshToken['refresh_key']) {
        //     // If no refresh token is found, redirect to login
        //     this.loginService.logOut();
        //     return EMPTY;
        // }

        return this.loginService.refreshToken(refreshToken['refresh_key']).pipe(
            take(1),
            switchMap((resp: any) => {
                StorageManager.getManager().set(CacheKeys.JWT_TOKEN, {
                    access_key: resp.accessToken.trim(),
                    identity: 'user'
                });
                StorageManager.getManager().set(CacheKeys.JWT_REFRESH_TOKEN, {
                    refresh_key: resp.refreshToken.trim(),
                    identity: 'refresh'
                });

                const newRequest = req.clone({
                    setHeaders: { Authorization: `Bearer ${resp.accessToken}` }
                });

                return next.handle(newRequest);
            }),
            catchError((error) => {
                this.loginService.logOut();
                return EMPTY;
            })
        );
    }
}
