import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmltotext'
})
export class HtmltotextPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value.replace(/<[^>]+>/gm, '') : '';
  }
  transformText(value: string): string {
    // Handle null or undefined values
    if (value === null || value === undefined || value.trim() === '' || value === 'null' || value === 'undefined') {
      return '--';
    }
    return value;
  }
}
