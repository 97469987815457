import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceUtils } from '../shared/utils/service-utils';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private http: HttpClient) { }

  public getConfig(){
      return this.http.get(ServiceUtils.APPCONFIG_URL);
  }
}
