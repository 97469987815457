import { Component, OnInit, Inject } from '@angular/core';
import { Utils } from 'src/app/shared/utils/utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/indicator/services/interface/base-response-dto';
import { Attachment } from 'src/app/indicator/services/interface/attachment';
import { ContentType } from 'src/app/indicator/services/interface/content-type';


@Component({
  selector: 'app-content-view-dialog',
  templateUrl: './content-view-dialog.component.html',
  styleUrls: ['./content-view-dialog.component.scss']
})
export class ContentViewDialogComponent implements OnInit {
  mimeType: string;
  base64Content: SafeResourceUrl;
  contentType: ContentType;
  title: string;
  loading = true;

  ContentType = ContentType;

  renderContent = (content: Content | Attachment) => {
    this.mimeType = Utils.mimeType(content.filename);
    this.contentType = Utils.contentType(this.mimeType);
    const santizedString = Utils.sanitize(Utils.base64UrlString(<string>content.file, content.filename));
    this.base64Content = this.sanitizer.bypassSecurityTrustResourceUrl(santizedString);
  }

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ContentViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContentViewDialogData) {

    this.title = data.title;

    if (data.getContentObservable) {
      data.getContentObservable.subscribe(response => {
        if (response.success) {
          if (response.body) {
            const content: Attachment = response.body;
            this.renderContent(content);
          }
        }
      }, error => {

      }, () => {
        this.loading = false;
      });
    }else if (data.content){
        this.renderContent(data.content);
    }
  }

  ngOnInit() {
  }

}

export interface ContentViewDialogData {
  title: string;
  getContentObservable?: Observable<BaseResponse<Attachment>>
  content?: Content
}

interface Content {
  filename: string,
  file: string
}

